









































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'
import { CodesApplyFiltersType } from '@/types/codes'

@Component({
  components: { FilterTags, InputCommon, TableColumnHeader },
})
export default class CodesList extends Vue {
  @Prop({ type: Array })
  tableData!: object[]

  @Prop({ type: String })
  filter!: string

  @Prop({ type: Number })
  total?: number

  @Prop({ type: Number })
  selected?: number

  @Prop({ type: Boolean })
  loading?: boolean

  moreTags = false

  page = 1

  codesStatus = {
    NEW: 'Новый',
    REQUESTED: 'Ожидает',
    ACTIVATED: 'Активированный',
  }

  get showDateActivated() {
    if (this.filter === 'ACTIVATED' || this.filter === 'BLOCKED') {
      return true
    } else {
      return false
    }
  }

  get showedCodesCount() {
    if (this.total) {
      return 10 * this.page > this.total ? this.total : 10 * this.page
    } else {
      return 0
    }
  }

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  setTableRowStatus(dateBlock: string | null, state: string): string {
    if (dateBlock !== null || state !== 'ACTIVATED') {
      return 'color-dark'
    } else {
      return 'color-gray'
    }
  }

  handleShowCodeInfo(row: { id: number }): void {
    this.$emit('show-code-info', row.id)
  }

  handleChangePage(): void {
    this.$emit('change-page', { offset: (this.page - 1) * 10 })
  }

  handleAddFilter(filter: CodesApplyFiltersType): void {
    this.$emit('add-filter', {
      data: filter.data,
      target: filter.target,
      dateFilters: filter.dateFilters,
    })
  }

  handleChangeOrder(data: object): void {
    this.$emit('change-sort', data)
  }
}
