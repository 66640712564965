





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReportsListItemType } from '@/types/reports'

@Component
export default class ReportsCard extends Vue {
  @Prop({ type: Object })
  report!: ReportsListItemType

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleShowDetail(): void {
    this.$emit('show-detail', this.report.id)
  }
}
