


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { CodesListItemType } from '@/types/codes'

@Component
export default class CodesCard extends Vue {
  @Prop({ type: Object })
  code!: CodesListItemType

  codesStatus = {
    NEW: 'Новый',
    REQUESTED: 'Ожидает',
    ACTIVATED: 'Активированный',
  }

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleShowCodeDetail(): void {
    this.$emit('show-detail', this.code.ID)
  }
}
