





















import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { DcTextarea } from 'dc-ui-library/.dist/types/packages/components'

@Component
export default class TextAreaCommon extends Vue {
  $refs!: {
    textArea: DcTextarea
  }

  @Model('input')
  value!: string

  @Prop({ type: String })
  label?: string

  focus = false

  handleTextInput(inputValue: String): void {
    this.$emit('input', inputValue)
  }

  handleClickOnLabel(): void {
    const input = this.$refs.textArea?.$el as HTMLLabelElement
    if (input) {
      input.focus()
    }

    this.focus = true
  }
}
