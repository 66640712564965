






























































































import { Vue } from 'vue-property-decorator'
import { PromotionsItemListType } from '@/types/promotions'

export default class PromotionDetailContent extends Vue {
  promotionRule = {
    counties: 'Страна',
    regions: 'Регион',
    cities: 'Город',
    shops: 'Магазин',
    shops_by_name: 'Магазин',
    users: 'Участник',
    artnums: 'Артикул',
    masks: 'Код',
    prefixes: 'Префикс',
  }

  get promotionsDetail(): PromotionsItemListType {
    return this.$store.getters['promotions/promotionsDetail']
  }

  setBonus(bonus: string): string {
    const result = JSON.parse(bonus)

    if (result.type === 'percent') {
      return `${result.number}%`
    } else {
      return `+ ${result.number}`
    }
  }

  async handleShowOtherInfo(key: string, value: string) {
    if (key === 'users') {
      this.$emit('show-member-info', value)
    } else if (key === 'shops' || key === 'shops_by_name') {
      this.$emit('show-shop-info', value)
    }
  }

  setImage(image: string): object {
    let imgArray = JSON.parse(image)

    imgArray.forEach((img, index) => {
      imgArray[index] = process.env.VUE_APP_BASE_URI + img
    })

    return imgArray
  }
}
