








































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReportsListItemType } from '@/types/reports'

@Component
export default class ReportsDetail extends Vue {
  @Prop({ type: Object })
  reportsDetail!: ReportsListItemType

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleDownloadCode(id: string): void {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }
}
