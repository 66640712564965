












































































































































import { Component, Vue } from 'vue-property-decorator'
import { Upload } from 'element-ui'
import { UserResponseType } from '@/types/user'
import TextAreaCommon from '@/components/common/TextAreaCommon.vue'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'

export type CodesFormType = {
  vendor: null | string
  importFile: null | any
  QR: boolean
  reportName: string
  reportNote: string
}

@Component({
  components: { DialogInfo, SelectCommon, InputCommon, TextAreaCommon },
})
export default class CreateCodes extends Vue {
  showExample = '0'
  form: CodesFormType = {
    vendor: null,
    importFile: null,
    QR: true,
    reportName: '',
    reportNote: '',
  }

  fileName = ''

  createCode = false
  createCodeError = false

  vendorOptions = [
    { label: 'Магазин 1', value: '1' },
    { label: 'Магазин 2', value: '2' },
  ]

  $refs!: {
    upload: Upload
  }

  get userData(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get importResponse(): string {
    return this.$store.getters['codes/importResponse']
  }

  handleUploadFile(file): void {
    this.form.importFile = file.raw
    this.fileName = file.name
  }

  handleDeleteFile(): void {
    this.form.importFile = null
    this.$refs.upload.clearFiles()
  }

  async handleCreateCodes(): Promise<void> {
    if (this.userData.role !== 'admin') {
      this.form.vendor = this.userData.vendor_id
    }

    if (this.form.importFile && this.form.vendor) {
      await this.$store.dispatch('codes/importCodes', this.form)

      if (this.importResponse) {
        this.$emit('success-create')

        await this.$store.dispatch('notifications/getNotificationsCount')
      } else {
        this.$emit('error-create')
      }
    } else {
      this.$emit('error-create')
    }
  }

  handleDownloadCode(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + '/api/file/downloadExample/'
    )
    link.setAttribute('download', 'exampleFile')

    link.click()

    link.remove()
  }

  handleShowExample(): void {
    this.showExample = +this.showExample ? '0' : '1'
  }

  handleCloseCreating(): void {
    this.$emit('close')
  }

  mounted(): void {
    this.$store.dispatch('user/getUserData')
  }
}
