


















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: { InputCommon, DialogInfo, TableColumnHeader },
})
export default class CodesArray extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  tableData!: object[]

  @Prop({ type: Number })
  total?: number

  page = 1
  downloadCode = false

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  get showedCodesCount() {
    if (this.total) {
      return 10 * this.page > this.total ? this.total : 10 * this.page
    } else {
      return 0
    }
  }

  handleDownloadCode(id: string): void {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }

  handleChangePage(): void {
    this.$emit('change-page', { offset: (this.page - 1) * 10 })
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleChangeOrder(data: object): void {
    this.$emit('change-sort', data)
  }
}
