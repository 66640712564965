




















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { CodesListItemType } from '@/types/codes'

@Component
export default class CodesDetail extends Vue {
  @Prop({ type: Object })
  codesDetail!: CodesListItemType

  @Prop({ type: Boolean })
  isAnimationEnd!: boolean

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleBlockCode(): void {
    this.$emit('block-code')
  }

  handleShopInfoOpen(): void {
    this.$emit('shop-info-open', this.codesDetail.SHOP_ID)
  }

  handlePromotionDetailOpen(): void {
    this.$emit('promotion-detail-open', this.codesDetail.ACTIVITY_ID)
  }
}
